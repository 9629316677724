<template>
  <v-autocomplete
    v-model="country"
    outlined
    hide-details="auto"
    validate-on-blur
    :items="countryData"
    :label="label"
    :loading="loading"
    :disabled="disabled"
    :rules="rules"
    :placeholder="$trans('autocomplete_hint')"
    @input="inputEventHandle"
  >
    <template #no-data>
      <div class="v-subheader theme--light">
        {{ $trans("nothing_found_here") }}
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import getCountries from "@/lib/calendesk-js-library/forms/countries";

export default {
  name: "CCountryAutocomplete",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Country",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      country: this.value,
      countries: getCountries(),
    };
  },
  computed: {
    countryData() {
      return this.countries.map((el) => ({
        text: el.name,
        value: el.iso2,
      }));
    },
  },
  watch: {
    value() {
      this.country = this.value;
    },
  },
  methods: {
    inputEventHandle() {
      this.$emit("input", this.country);
    },
  },
};
</script>
