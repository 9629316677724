export default {
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [Array, Number, String],
      default: () => [],
    },
    hideActionButtons: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "auto",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    smallChips: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItems: this.value,
    };
  },
  watch: {
    value(val) {
      if (!val || (this.multiple && val.length === 0)) {
        this.selectedItems = this.multiple ? [] : null;
      } else {
        this.selectedItems = this.value;
      }
    },
  },
  methods: {
    removeChip(item) {
      if (this.multiple) {
        const index = this.selectedItems.findIndex((element) => {
          let elementId = typeof element === "number" ? element : element.id;
          const itemId = typeof item === "number" ? item : item.id;

          return elementId === itemId;
        });

        if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
      } else {
        this.selectedItems = null;
      }

      this.$emit("input", this.selectedItems);
    },
  },
};
