<template>
  <div>
    <v-row>
      <v-col class="d-flex">
        <div v-if="prependIcon" class="mr-2 mt-4">
          <v-icon>
            {{ prependIcon }}
          </v-icon>
        </div>
        <v-autocomplete
          v-model="country"
          style="max-width: 80px"
          :items="countries"
          :filter="customPhoneFilter"
          return-object
          :label="$trans('area_code')"
          :outlined="outlined"
          :dark="dark"
          :dense="dense"
          :filled="filled"
          :flat="flat"
          :readonly="readonly"
          :rounded="rounded"
          :disabled="disabled"
          validate-on-blur
          :rules="[...rules]"
          single-line
          class="mr-2"
          :hide-details="hideDetails"
          @input="handleChangeAction"
          @change="handleChangeAction"
        >
          <template #no-data>
            <div class="v-subheader theme--light">
              {{ $trans("nothing_found_here") }}
            </div>
          </template>

          <template #selection="{ item }">
            <div class="d-flex align-center text-center">
              {{ item.showDialCode }}
            </div>
          </template>
          <template #item="{ item }">
            <div class="d-flex align-center">
              <span>{{ item.showDialCode }}</span>
              <small class="ml-2">{{ item.name }}</small>
            </div>
          </template>
        </v-autocomplete>

        <v-text-field
          v-model="phone"
          :label="$trans('phone')"
          :append-icon="appendIcon"
          :clearable="clearable"
          :dark="dark"
          :dense="dense"
          :filled="filled"
          :flat="flat"
          :outlined="outlined"
          :readonly="readonly"
          :rounded="rounded"
          :clear-icon="clearIcon"
          :placeholder="country ? country.examplePhoneNumber : null"
          :disabled="disabled"
          validate-on-blur
          :rules="[...rules, phoneNumberValidation]"
          :hide-details="hideDetails"
          @input="handleChangeAction"
          @blur="handleBlurAction"
          @keydown.enter.prevent="handleEnterClicked"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import getCountries from "@/lib/calendesk-js-library/forms/countries";
import { customPhoneFilter } from "@/lib/calendesk-js-library/tools/helpers";

const PNF = require("google-libphonenumber").PhoneNumberFormat;
const phoneUtil =
  require("google-libphonenumber").PhoneNumberUtil.getInstance();

export default {
  name: "PhoneInput",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    prependIcon: {
      type: String,
      default: "",
    },
    appendIcon: {
      type: String,
      default: "",
    },
    hideDetails: {
      type: String,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    clearIcon: {
      type: String,
      default: "$close",
    },
    filled: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    defaultCountryCode: {
      type: String,
      default: () => "gb",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    countries: getCountries(),
    phone: null,
    country: {},
    validationFailed: false,
  }),
  computed: {
    phoneNumberValid() {
      if (!this.phone) {
        return true;
      }

      if (
        !this.country ||
        !this.country.iso2UpperCase ||
        this.phone.length < 5 ||
        this.phone.length > 17
      ) {
        return false;
      }

      try {
        const phone = this.phone.replace(/\D/g, "");
        const phoneNumber = this.generatePhoneNumber(
          phone,
          this.country.iso2UpperCase
        );
        return phoneUtil.isValidNumberForRegion(
          phoneNumber,
          this.country.iso2UpperCase
        );
      } catch {
        return false;
      }
    },
  },
  watch: {
    value() {
      this.updateProps();
    },
    defaultCountryCode() {
      this.setDefaultCountry();
    },
  },
  created() {
    this.setDefaultCountry();
    this.updateProps();
  },
  methods: {
    emitValue() {
      if (!this.phoneNumberValid || !this.phone || !this.country) {
        this.$emit("input", null);
        return;
      }

      try {
        const phoneNumber = this.generatePhoneNumber(
          this.phone,
          this.country.iso2UpperCase
        );
        this.$emit("input", phoneUtil.format(phoneNumber, PNF.E164));
      } catch {
        this.$emit("input", null);
      }
    },
    updateProps() {
      if (
        !this.value ||
        (this.value && (this.value.length > 17 || this.value.length < 4))
      ) {
        return;
      }

      try {
        const number = phoneUtil.parse(this.value);
        const countryCode = phoneUtil.getRegionCodeForNumber(number);

        if (countryCode) {
          this.country = this.searchCountry(
            this.countries,
            countryCode.toLowerCase()
          );

          // The replace with a country code is done on the full value, there is always a country code there.
          this.phone = this.value
            .replace(/\D/g, "")
            .replace(this.country.dialCode, "");

          this.emitValue();
        }
      } catch (error) {
        // ignore silent errors with parsing numbers
        // set country which is very likely to choose by user
        this.setDefaultCountry();
      }
    },
    setDefaultCountry() {
      if (this.defaultCountryCode) {
        this.country = this.searchCountry(
          this.countries,
          this.defaultCountryCode.toLowerCase()
        );

        if (!this.country) {
          this.country = this.searchCountry(this.countries, "gb");
        }
      }
    },
    handleChangeAction() {
      this.emitValue();
    },
    handleEnterClicked() {
      this.handleBlurAction();
      this.$emit("enter");
    },
    handleBlurAction() {
      this.handleChangeAction();
    },
    phoneNumberValidation() {
      if (this.phoneNumberValid) {
        return true;
      }

      return this.$trans("phone_is_invalid");
    },
    searchCountry(array, countryCode) {
      return array.find((country) => country.iso2 === countryCode);
    },
    generatePhoneNumber(phoneNumber, countryCode) {
      return phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);
    },
    customPhoneFilter,
  },
};
</script>
