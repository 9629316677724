<template>
  <v-form ref="UserForm" @submit.prevent="handleSave">
    <v-container class="form__container" fluid>
      <v-row>
        <v-col v-if="!dialogMode" cols="12" md="4" class="pb-4">
          <calendesk-image-select
            v-model="image"
            :disabled="isLoading"
            avatar
            :select-image-button-text="$trans('select')"
            :remove-image-button-text="$trans('remove')"
            @input="setAvatar"
          />
        </v-col>
        <v-col cols="12" :md="dialogMode ? 12 : 8" class="pb-0 mb-0">
          <v-container
            fluid
            class="pa-0 ma-0"
            @keydown.enter.prevent="handleSave"
          >
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.name"
                  :disabled="isLoading"
                  :label="$trans('name')"
                  :rules="form.name ? [rules.maxChars(50)] : []"
                  outlined
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.surname"
                  :disabled="isLoading"
                  :label="$trans('surname')"
                  :rules="form.surname ? [rules.maxChars(50)] : []"
                  outlined
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.email"
                  :disabled="
                    isLoading ||
                    (selectedUserIsAdmin && !loggedUserIsAdmin) ||
                    (selectedUserIsRoot && !loggedUserIsRoot)
                  "
                  :label="$trans('email')"
                  :rules="form.email ? [rules.email, rules.maxChars(60)] : []"
                  validate-on-blur
                  outlined
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="12" md="6">
                <phone-input
                  v-model="form.default_phone"
                  :disabled="isLoading"
                  :default-country-code="
                    $config('preferred_phone_countries')[0]
                  "
                  hide-details="auto"
                  @enter="handleSave"
                />
              </v-col>
              <v-col cols="12" md="6">
                <groups-field
                  v-model="form.groups"
                  small-chips
                  deletable-chips
                  :disabled="isLoading"
                  multiple
                />
              </v-col>
              <v-col cols="12" md="6">
                <birth-field
                  :value="form.date_of_birth"
                  :disabled="isLoading"
                  @change="form.date_of_birth = $event"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <tip-tap
            v-model="form.description"
            :label="$trans('private_notes_for_customer_label')"
            :placeholder="$trans('you_can_add_note_here')"
            :disabled="isLoading"
            @input="form.description = $event"
          />
          <calendesk-information-message
            v-if="form.description && form.description.length > 65000"
            color="red-light"
            additional-class="danger--text my-4"
            icon-color="danger"
            icon="$alert"
          >
            {{ $trans("max_n_characters", { max: 65000 }) }}
          </calendesk-information-message>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="pt-0 pb-0 mb-0" cols="12">
          <v-checkbox
            v-model="isCompany"
            :disabled="isLoading"
            :label="$trans('company_business_activity')"
            hide-details
          />
        </v-col>
      </v-row>
      <v-form ref="CompanyDataForm" class="mt-2">
        <c-company-data
          v-show="isCompany"
          ref="company_data"
          :my-form="form.default_address"
          :save-button="false"
          :loading="isLoading"
          @defaultAddress="getDefaultAddress"
        />
      </v-form>
      <v-row v-if="!dialogMode" no-gutters>
        <v-col cols="12">
          <v-checkbox
            v-model="showAdvanceSettings"
            :label="$trans('advance_settings_label')"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row v-if="showAdvanceSettings">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="form.stripe_id"
            :disabled="isLoading"
            :label="$trans('stripe_id_label')"
            :rules="form.stripe_id ? [rules.maxChars(255)] : []"
            validate-on-blur
            outlined
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="form.fakturownia_id"
            :disabled="isLoading"
            :label="$trans('fakturownia_id_label')"
            :rules="form.fakturownia_id ? [rules.maxChars(255)] : []"
            validate-on-blur
            outlined
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="form.identity_number"
            :disabled="isLoading"
            :label="$trans('identity_number_label')"
            :rules="form.identity_number ? [rules.maxChars(255)] : []"
            validate-on-blur
            outlined
          />
        </v-col>
      </v-row>
      <v-row v-if="!isEdit && form.email" justify="start">
        <v-col md="12" class="checkbox-align">
          <v-checkbox
            v-model="sendResetPassword"
            :disabled="!form.email"
            :label="$trans('send_email_with_password_change')"
          />
        </v-col>
      </v-row>
      <v-row v-if="!dialogMode" justify="start">
        <v-col>
          <v-btn
            :disabled="isLoading"
            :loading="isLoading"
            color="primary"
            type="submit"
            depressed
          >
            <span v-text="selectedUser ? $trans('save') : $trans('add')" />
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import { config } from "@/lib/calendesk-js-library/prototypes/config";
import {
  email,
  maxChars,
  minChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import CalendeskImageSelect from "@/lib/calendesk-js-library/components/CalendeskImageSelect";
import GroupsField from "@/components/Forms/GroupsField";
import PhoneInput from "@/components/Forms/PhoneInput/PhoneInput";
import BirthField from "@/components/Forms/BirthField";
import CCompanyData from "@/components/CCompanyData/CCompanyData";
import statsActions from "@/calendesk/mixins/statsActions";
import roleActions from "@/calendesk/mixins/roleActions";
import TipTap from "@/lib/calendesk-js-library/components/TipTap";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";
import {
  employeePermissions,
  hasPermission,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "UserFullForm",
  components: {
    CalendeskInformationMessage,
    TipTap,
    CalendeskImageSelect,
    GroupsField,
    PhoneInput,
    BirthField,
    CCompanyData,
  },
  mixins: [statsActions, roleActions],
  props: {
    dialogMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statsMode: this.$helpers.statsMode.users,
      showAdvanceSettings: false,
      isLoading: false,
      invalidForm: false,
      companyName: config("company_name"),
      isCompany: false,
      rules: {
        required,
        email,
        minChars,
        maxChars,
      },
      form: {
        name: null,
        surname: null,
        email: null,
        default_phone: null,
        date_of_birth: null,
        description: null,
        groups: [],
        stripe_id: null,
        fakturownia_id: null,
        identity_number: null,
        default_address: {
          name: null,
          street: null,
          postal_code: null,
          city: null,
          country_iso_code: null,
          tax_number: null,
          vat_iso_prefix: null,
        },
      },
      image: null,
      imageUpdated: false,
      sendResetPassword: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedUser: "user/getSelectedUser",
    }),
    isEdit() {
      return this.$route.name === "user";
    },
    addressIsEmpty() {
      return this.$helpers.addressIsEmpty(this.selectedUser.default_address);
    },
  },
  watch: {
    selectedUser(val) {
      if (val !== null) {
        this.fillForm();
      }
    },
  },
  created() {
    this.$nextTick(() => {
      document.documentElement.scrollTop = 0;
      this.fillForm();
    });
  },
  methods: {
    ...mapActions({
      updateUser: "user/update",
      getUser: "user/get",
      addUser: "user/add",
      refreshUsers: "user/refreshUsers",
      refreshBookings: "booking/refreshBookings",
      refreshGroups: "group/refreshGroups",
    }),
    ...mapMutations({
      setSelectedUser: "user/SET_SELECTED_USER",
      setNewUser: "user/SET_NEW_USER",
    }),
    getDefaultAddress(val) {
      this.form.default_address = val;
    },
    setAvatar(image) {
      this.imageUpdated = true;
      this.image = image;
    },
    checkValidation() {
      const form = this.$refs.UserForm.validate();
      const companyForm = this.isCompany
        ? this.$refs.CompanyDataForm.validate()
        : true;
      this.invalidForm = !form || !companyForm;
    },
    getAddress(obj) {
      const path = this.form.default_address;
      if (obj && path) {
        for (const prop in obj) {
          if (obj[prop]) path[prop] = obj[prop];
        }
      }
    },
    formatGroups(groups) {
      return groups.length > 0 ? groups.map((x) => x.id) : groups;
    },
    fillForm() {
      if (this.selectedUser && !this.dialogMode) {
        this.form.name = this.selectedUser.name;
        this.form.surname = this.selectedUser.surname;
        this.form.email = this.selectedUser.email;
        this.form.default_phone = this.selectedUser.default_phone
          ? this.selectedUser.default_phone.e164
          : null;
        this.form.date_of_birth = this.selectedUser.date_of_birth;
        this.form.fakturownia_id = this.selectedUser.fakturownia_id;
        this.form.identity_number = this.selectedUser.identity_number;
        this.form.stripe_id = this.selectedUser.stripe_id;
        this.form.description = this.selectedUser.description;
        this.image = this.selectedUser.default_image;
        this.form.groups = this.selectedUser.groups
          ? this.formatGroups(this.selectedUser.groups)
          : [];
        if (this.selectedUser && !this.addressIsEmpty) {
          this.isCompany = true;
          this.getAddress(this.selectedUser.default_address);
          if (this.selectedUser.default_address.country_iso_code) {
            this.form.default_address.country_iso_code =
              this.selectedUser.default_address.country_iso_code.toLowerCase();
          }
        }
      }
    },
    handleSave() {
      if (this.form.description && this.form.description.length > 65000) {
        errorNotification("description_too_long", {}, false);
        return;
      }

      this.isLoading = true;
      this.checkValidation();

      if (this.isCompany) {
        this.$refs.company_data.emitAddress();
      }
      if (!this.invalidForm) {
        this.selectedUser && !this.dialogMode ? this.update() : this.add();
      } else {
        this.isLoading = false;
        errorNotification("form_is_invalid", {}, false);
      }
    },
    getFormData(isAdding) {
      const data = this.$helpers.copyObj(this.form);
      if (this.imageUpdated) {
        data.default_image_id = this.image ? this.image.id : null;
      }

      if (!this.isCompany) {
        data.default_address = {
          name: null,
          street: null,
          postal_code: null,
          city: null,
          country_iso_code: null,
          vat_iso_prefix: null,
          tax_number: null,
        };
      }

      if (isAdding) {
        data.send_reset_password = this.sendResetPassword ? 1 : 0;
      } else {
        data.id = this.selectedUser.id;
      }

      return data;
    },
    update() {
      pushEvent("updateUser");

      this.updateUser(this.getFormData(false))
        .then((response) => {
          this.setSelectedUser(response);
          this.fillForm();
          successNotification("update_success");
          this.isLoading = false;
          this.refreshUsers();
          this.refreshBookings();
          this.refreshGroups();
          this.$router.push({ name: "users" });
        })
        .catch((error) => {
          this.showErrorMessage(error);
          this.isLoading = false;
        });
    },
    async add() {
      pushEvent("createUser");
      this.$emit("isAddingClient", true);
      this.addUser(this.getFormData(true))
        .then(async (response) => {
          this.refreshUsers();
          this.refreshGroups();
          this.fetchStats();
          this.isLoading = false;
          this.$emit("isAddingClient", false);
          this.$emit("userAdded", response);

          if (!this.dialogMode) {
            this.setSelectedUser(response);
            this.redirectBack();
          } else {
            this.setNewUser(response);
          }
        })
        .catch((error) => {
          this.showErrorMessage(error);
          this.isLoading = false;
          this.$emit("isAddingClient", false);
        });
    },
    redirectBack() {
      if (hasPermission(employeePermissions.SHOW_USERS)) {
        this.$router.push({ name: "users" });
      } else if (
        hasPermission(employeePermissions.SHOW_BOOKINGS) ||
        hasPermission(employeePermissions.SHOW_OWN_BOOKINGS)
      ) {
        this.$router.push({ name: "sales", params: { tab: "events" } });
      }
    },
    showErrorMessage(error) {
      let errorMessage = this.$trans("error_occurred");

      if (this.$helpers.emailIsTakenError(error)) {
        errorMessage = this.$trans("email_is_taken");
      }
      errorNotification(errorMessage, error, false);
    },
  },
};
</script>
<style lang="scss">
.checkbox-align .v-input__slot {
  align-items: start;
}
</style>
