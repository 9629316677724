<template>
  <v-autocomplete
    v-model="selectedItems"
    small-chips
    deletable-chips
    outlined
    :items="groups || []"
    :height="selectedItems.length > 0 ? undefined : 10"
    :label="$trans('group')"
    item-text="name"
    item-value="id"
    multiple
    :loading="loading"
    :disabled="disabled"
    :no-data-text="$trans('nothing_here')"
    clearable
    :dense="dense"
    hide-details
    @change="$emit('input', selectedItems)"
  >
    <template #no-data>
      <div class="v-subheader theme--light">
        {{ $trans("nothing_found_here") }}
      </div>
    </template>

    <template #selection="data">
      <div class="d-flex align-center pointer" style="max-width: 99%">
        <template v-if="chips || smallChips">
          <v-chip
            :small="smallChips"
            :disabled="disabled"
            :close="deletableChips"
            @click:close="removeChip(data.item)"
          >
            <span class="text-truncate">{{ data.item.name }}</span>
          </v-chip>
        </template>
        <template v-else>
          <span class="text-truncate">{{ data.item.name }}</span>
        </template>
      </div>
    </template>

    <template #item="data">
      <div class="d-flex align-center pointer my-2">
        <span class="text-break cd-v-select-line-height">{{
          data.item.name
        }}</span>
      </div>
    </template>

    <template #append-outer>
      <slot />

      <div v-if="loggedUserCanAddGroups">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div
              class="outlined-icon"
              :class="{
                'outlined-icon-dense ml-1': dense,
                'ml-3': !dense,
              }"
              @click="handleAddNewGroup"
              v-on="on"
            >
              <v-icon>$plus</v-icon>
            </div>
          </template>
          {{ $trans("add") }}
        </v-tooltip>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import dialogTypes from "@/components/ConfirmDialogs/confirmDialogTypes";
import roleActions from "@/calendesk/mixins/roleActions";
import sharedField from "@/calendesk/mixins/sharedField";

export default {
  name: "GroupsField",
  mixins: [sharedField, roleActions],
  computed: {
    ...mapGetters({
      groups: "group/groups",
      newGroup: "group/getNewGroup",
    }),
  },
  watch: {
    newGroup(val) {
      if (val) {
        this.selectedItems.push(val.id);
        this.$emit("groupAdded");
      }
    },
  },
  created() {
    if (!this.groups) {
      this.fetchGroups();
    }
  },
  methods: {
    ...mapActions({
      fetchGroups: "group/fetchAll",
    }),
    handleAddNewGroup() {
      this.openConfirmDialog({
        type: dialogTypes.ADD_OR_UPDATE_GROUP,
        width: 780,
      });
    },
  },
};
</script>
