<template>
  <v-menu
    v-model="menuDate"
    :disabled="disabled"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template #activator="{ on }">
      <v-text-field
        :disabled="disabled"
        :value="formattedDate"
        :label="$trans('birthday')"
        readonly
        outlined
        append-icon="$calendar"
        :dense="dense"
        hide-details="auto"
        @click:append="menuDate = true"
        v-on="on"
      />
    </template>
    <v-date-picker
      ref="picker"
      v-model="date"
      first-day-of-week="1"
      :disabled="disabled"
      :max="maxDate"
      :min="minDate"
      no-title
      color="primary"
      class="data-picker-no-header"
      @input="menuDate = false"
    />
  </v-menu>
</template>

<script>
export default {
  name: "BirthField",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: null,
      menuDate: false,
      maxDate: this.$moment().format(this.$helpers.dateFormat),
      minDate: this.$moment()
        .subtract("130", "years")
        .format(this.$helpers.dateFormat),
    };
  },
  computed: {
    formattedDate() {
      if (this.date) {
        return this.$moment(new Date(this.date)).format(
          this.$helpers.dayMonthAndYearDate
        );
      }

      return null;
    },
  },
  watch: {
    value() {
      this.date = this.value;
    },
    date() {
      this.$emit("change", this.date);
    },
    menuDate(val) {
      val &&
        setTimeout(() => (this.$refs.picker.internalActivePicker = "YEAR"));
    },
  },
  methods: {},
};
</script>
