<template>
  <div
    class="calendesk-image-select"
    :class="{ vertical: vertical, outline: outline }"
  >
    <div
      v-if="!image"
      class="calendesk-image-select__no-image"
      :class="{ avatar: avatar }"
    >
      <v-icon size="48" color="darken_gray">
        {{ avatar ? "$account" : "$image" }}
      </v-icon>
    </div>
    <v-img
      v-if="image"
      :src="getImageUrl"
      :contain="contain"
      class="calendesk-image-select__image ma-4"
      :class="{ avatar: avatar }"
    />
    <div v-if="loggedUserCanUseImages" class="d-flex align-center">
      <v-btn
        :disabled="disabled"
        outlined
        color="secondary"
        class="calendesk-image-select__action-button"
        small
        @click="openAssetsDialog"
      >
        <v-icon left> $file</v-icon>
        {{ selectImageButtonText }}
      </v-btn>
      <v-btn
        v-if="displayRemoveImageButton && image"
        :disabled="disabled"
        depressed
        outlined
        class="ml-2 calendesk-image-select__remove-file"
        small
        @click="removeImageFunction"
      >
        {{ removeImageButtonText }}
      </v-btn>
    </div>
    <div v-if="outline && label" class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import sharedDialogTypes from "./dialogs/sharedDialogTypes";
import dialogSize from "./dialogs/dialogSize";
import { modeTypes } from "./assets/assetsTypes";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import {
  employeePermissions,
  hasPermission,
} from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "CalendeskImageSelect",
  mixins: [sharedActions],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    avatar: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: true,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    selectType: {
      type: Number,
      default: 0,
    },
    selectImageButtonText: {
      type: String,
      default: "Select",
    },
    displayRemoveImageButton: {
      type: Boolean,
      default: true,
    },
    removeImageButtonText: {
      type: String,
      default: "Remove",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contain: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      image: this.value,
    };
  },
  computed: {
    ...mapGetters({
      getSelected: "assets/getSelected",
      getConfirmSelected: "assets/getConfirmSelected",
      getSelectType: "assets/getSelectType",
    }),
    loggedUserCanUseImages() {
      // TODO: use roleActions when it will be available in the lib
      return hasPermission(employeePermissions.SHOW_USER_IMAGES);
    },
    getImageUrl() {
      return this.image
        ? this.image.default_image
          ? `${this.$config("image_base_url")}${this.image.default_image.name}`
          : `${this.$config("image_base_url")}${this.image.name}`
        : () => import("@/lib/calendesk-js-library/assets/placeholder.jpg");
    },
  },
  watch: {
    value() {
      this.image = this.value;
    },
    getConfirmSelected() {
      if (!this.getConfirmSelected || this.getSelectType !== this.selectType)
        return;
      this.image = this.getSelected;
      this.$emit("input", this.image);
      this.resetSelected();
    },
  },
  methods: {
    ...mapMutations({
      setMode: "assets/SET_MODE",
      setSelectType: "assets/SET_SELECT_TYPE",
    }),
    ...mapActions({
      resetSelected: "assets/resetSelected",
    }),
    openAssetsDialog() {
      this.setMode(modeTypes.SINGLE);
      this.setSelectType(this.selectType);
      this.openDialog({
        type: sharedDialogTypes.ASSETS_DIALOG,
        title: this.$trans("files"),
        size: dialogSize.FULL_SCREEN,
      });
    },
    removeImageFunction() {
      this.image = null;
      this.$emit("input", this.image);
    },
  },
};
</script>

<style lang="scss" scoped>
.calendesk-image-select {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: var(--v-snow_gray-base);
  border-radius: 4px;
}

.calendesk-image-select__no-image {
  height: 100px;
  width: 100px;
  min-height: 100px;
  min-width: 100px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  background: var(--v-background-base);
}

.calendesk-image-select__image {
  width: 100px;
  height: 100px;
  max-width: 100px;
  max-height: 100px;
  border-radius: 6px;
  background: var(--v-snow_gray-base);
}

.calendesk-image-select__action-button {
  margin: 0 1.75rem;
}

.calendesk-image-select__remove-file.v-btn {
  border-color: rgba(38, 50, 56, 0.16);
}

.avatar {
  border-radius: 50%;
}

.vertical {
  &.calendesk-image-select {
    flex-direction: column;
  }

  .calendesk-image-select__action-button {
    margin: 0.5rem 0;
  }
}

.outline {
  &.calendesk-image-select {
    position: relative;
    padding: 0.75rem;
    transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    border: solid 1px rgba(0, 0, 0, 0.42);
    background: var(--v-background-base);
  }

  &:hover {
    border-color: rgba(0, 0, 0, 0.86);
  }

  .label {
    position: absolute;
    top: -0.6rem;
    left: 0.5rem;
    padding: 0 0.2rem;
    background: var(--v-background-base);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>
